import React from "react";
import Xaaslogo from "../../../assets/Xaaslogo.png";
import { Row, Col, Form, Input, Alert, Button, message } from "antd";
import "../user.css";
import Index from "../Login/background";
import Footer from "../../../common/footer";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import OtpInput from "react-otp-input";

function ConfirmPassword(props) {
  const [msg, setmsg] = useState();
  const [loading, setloading] = useState(false);
  const [form] = Form.useForm();
  const [email, setemail] = useState(
    props.location?.state ? props.location?.state?.userName : ""
  );
  const history = useHistory();
  const [otp, setOtp] = useState();

  // Function to handle resend code
  const resendConfirmationCode = async () => {
    try {
      await Auth.forgotPassword(email).then(() => {
        message.success("Your resend code has been sent");
        setloading(false);
      });
    } catch (error) {
      console.log("error resend code :", error.message);
      setloading(false);
      setmsg({ text: error.message, type: "error" });
    }
  };

  // Function to confirmation code submit
  const confirmReset = async (e) => {
    setloading(true);
    setmsg({ text: "", type: "" });
    try {
      await Auth.forgotPasswordSubmit(email, e.code, e.password).then(() => {
        setloading(false);
        history.push({
          pathname: "/signin",
          state: { feedback: "Signin with new password.", userName: "" },
        });
      });
    } catch (error) {
      console.log("error resetting password:", error);
      setloading(false);
      setmsg({ text: error.message, type: "error" });
    }
  };

  const handleChange = (otp) => {
    setOtp(otp);
  };

  useEffect(() => {
    if (!props.location.state) {
      if (
        window.location.href.includes("confirmationcode=") &&
        window.location.href.includes("/reset-password") &&
        window.location.href.includes("email=")
      ) {
        let Data = {};
        Data.code = window.location.href
          .split("confirmationcode=")[1]
          .split("&email=")[0];
        form.setFieldsValue({
          code: Data.code,
          password: "",
        });
        setemail(window.location.href.split("email=")[1]);
      } else {
        history.push("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row justify="center">
      <Index />
      <Col span={12} md={12} lg={12} xs={24}>
        <Row
          gutter={24}
          justify="center"
          style={{
            display: "flex",
            alignItems: "center",
            height: "100vh",
            width: "100%",
            position: "relative",
            margin: 0,
          }}
        >
          <Col
            span={12}
            md={24}
            lg={12}
            xs={24}
            sm={24}
            style={{ textAlign: "left" }}
          >
            <img
              src={Xaaslogo}
              alt=""
              style={{ display: "flex", margin: "0 auto", height: 100 }}
            />
            {msg?.text && (
              <Alert
                style={{ marginBottom: "10px" }}
                type={msg?.type}
                showIcon={false}
                message={msg.text}
                banner
              />
            )}
            <Form
              form={form}
              onFinish={(e) => confirmReset(e)}
              layout="vertical"
              name="normal_signup"
              className="signup-form"
              requiredMark={false}
            >
              <h4
                style={{
                  color: "#416B88",
                  textAlign: "center",
                  fontWeight: "600",
                  margin: "10px",
                }}
              >
                <p
                  style={{ color: "#727272", fontWeight: "400", fontSize: 18 }}
                >
                  {" "}
                  Enter code sent in your email{" "}
                </p>
              </h4>
              <Form.Item
                className="otp"
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Please input your Confirmation Code!",
                  },
                ]}
              >
                <OtpInput
                  name="code"
                  id="code"
                  key="code"
                  shouldAutoFocus
                  className="otp-input"
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  separator={<span style={{ color: "white" }}> ---</span>}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "18px" }}>
                    Set your new password
                  </span>
                }
                name="password"
              >
                <Input.Password
                  style={{
                    borderRadius: "8px",
                    height: "44px",
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Row justify="space-between" align="middle">
                  <Col span={8}>
                    <Button type="link" onClick={resendConfirmationCode}>
                      <span
                        style={{
                          fontWeight: 600,
                          fontSize: "18px",
                          textAlign: "center",
                          color: "#416B88",
                        }}
                      >
                        Resend code
                      </span>
                    </Button>
                  </Col>
                  <Col span={10}>
                    <Button
                      size="large"
                      htmlType="submit"
                      block
                      loading={loading}
                      tabIndex={5}
                      style={{
                        background: "#416B88",
                        color: "#FFFFFF",
                        borderRadius: "8px",
                        height: "44px",
                        marginBottom: 0,
                        fontSize: 18,
                        fontWeight: "bold",
                      }}
                    >
                      <span>Submit</span>
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
          <Footer />
        </Row>
      </Col>
    </Row>
  );
}

export default ConfirmPassword;
