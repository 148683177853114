import {
  Dropdown,
  Menu,
  Form,
  Select,
  Button,
  Col,
  Row,
  Input,
  Table,
  message,
  Modal,
  Typography,
  Space,
  Drawer,
  Divider,
  Radio,
  Skeleton
} from "antd";
import React, { useEffect, useState } from "react";
import { MoreOutlined, LeftOutlined } from "@ant-design/icons";
import { emailPattern } from "../../utility/constants";
import InviteModalIcon from "../../assets/InviteModalIcon.svg";
import sdk from "../../sdk/Accounts";
import Moment from "react-moment";
import { useSelector } from "react-redux";

const Companies = (props) => {
  const [Companies, setCompanies] = useState([]);
  const [TableLoading, setTableLoading] = useState(true);
  const [compniesBackup, setcompniesBackup] = useState([]);
  const [SearchParams, setSearchParams] = useState({});
  const [SearchOption, setSearchOption] = useState("Name");
  const [loading, setLoading] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [addCompanyDrawer, setAddCompanyDrawer] = useState(false);
  const [addUserInviteDrawer, setAddUserInviteDrawer] = useState(false);
  const [inviteform] = Form.useForm();
  const [addcompanyform] = Form.useForm();
  const [inviteData, setinviteData] = useState();
  const [acct, setAcct] = useState(null);

  const { groups } = useSelector((state) => state.loginReducer);
  const [recordData, setRecordData] = useState({});

  const [searchForm] = Form.useForm();

  const handleCancel = () => {
    setIsInviteModalOpen(false);
  };

  const showInviteUserDrawerOnClose = () => {
    setAddUserInviteDrawer(false);
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  const getCompanyData = async (reset = false) => {
    let nextToken = null;
    setTableLoading(true);
    setCompanies([]);
    setcompniesBackup([]);
    do {
      let response = await sdk.getAllUsers(nextToken);
      let result = response?.data?.listAccounts?.items;
      result && setCompanies((olddata) => olddata.concat(result));
      result && setcompniesBackup((olddata) => olddata.concat(result));
      nextToken = response?.data?.listAccounts?.nextToken;
    } while (nextToken);
    setTableLoading(false);
  };

  const { Search } = Input;

  const columns = [
    {
      title: (
        <Typography.Text className="font-weight">Account ID</Typography.Text>
      ),
      width: "25%",
      dataIndex: "GroupName",
      key: "name",
      render: (name, record) => (
        <span style={{ fontSize: 16 }}>{record?.id}</span>
      ),
      sorter: (a, b) => String(a?.id).localeCompare(b?.id),
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: (
        <Typography.Text className="font-weight">Company Name</Typography.Text>
      ),
      width: "15%",
      dataIndex: "Description",
      key: "Descriptione",
      render: (Description, record) => {
        return (
          <>
            <span style={{ fontSize: 16 }}>{record?.company ? (record?.company) : "-"}</span>
          </>
        );
      },
      sorter: (a, b) => String(a?.company).localeCompare(b?.company),
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: <Typography.Text className="font-weight">View As</Typography.Text>,
      width: "10%",
      dataIndex: "",
      key: "viewas",
      render: (Description, record) => {
        return (
          <>
            <span
              style={{ fontSize: 16 }}
              title="Click here to select company for admin view"
              className="company-name"
              onClick={() => props.selectCompany(record, "admin")}
            >
              Admin
            </span>
          </>
        );
      },
    },
    {
      title: (
        <Typography.Text className="font-weight">Last Updated</Typography.Text>
      ),
      width: "15%",
      dataIndex: "updatedAt",
      key: "last_modified",
      render: (modifyDate) => (
        <span style={{ fontSize: 16 }}>
          <Moment date={modifyDate} format="lll" />
        </span>
      ),
      sorter: (a, b) => {
        return String(a?.updatedAt).localeCompare(b?.updatedAt);
      },
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: <Typography.Text className="font-weight">Users</Typography.Text>,
      width: "10%",
      dataIndex: "users",
      key: "users",
      render: (recordData) => (
        <span style={{ fontSize: 16 }}>{recordData?.length}</span>
      ),
      sorter: (a, b) => {
        return String(a?.users?.length).localeCompare(b?.users?.length);
      },
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "",
      width: "3%",
      dataIndex: "id",
      key: "actions",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <Dropdown
            overlay={
              <Menu>
                <Menu.ItemGroup>
                  <Menu.Item key="invite">
                    <a
                      href={() => false}
                      onClick={() => {
                        setIsInviteModalOpen(true);
                        setRecordData(record);
                      }}
                      style={{ fontSize: 18 }}
                    >
                      Invite User
                    </a>
                  </Menu.Item>
                </Menu.ItemGroup>
              </Menu>
            }
          >
            <MoreOutlined style={{ fontSize: "25px", color: "#959595" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  const handle_search = (value) => {
    setCompanies(compniesBackup);
    setSearchParams({
      Name: value["search_type"],
      Value: value["search_value"] ? value["search_value"] : "",
    });
  };

  useEffect(() => {
    searchCompany(SearchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchParams]);

  useEffect(() => {
    searchForm.setFieldsValue({ search_value: null });
    // eslint-disable-next-line
  }, [SearchOption]);

  const searchCompany = async (SearchParams) => {
    await setTableLoading(true);
    let tempNextToken = null;
    if (Object.keys(SearchParams).length > 0) {
      if (String(SearchParams.Value).length === 0){
        setCompanies(compniesBackup);
        setTableLoading(false);
      } else {
        let filter = {};
        if (SearchParams.Name === "Name") {
          filter["company_loweredcased"] = {
            contains: String(SearchParams.Value).toLowerCase(),
          };
        } else {
          filter[SearchParams.Name] = { contains: SearchParams.Value };
        }

        setCompanies([]);
        do {
          let companyList = await sdk.getAllUsers(tempNextToken, filter);
          setCompanies((olddata) =>
            olddata.concat(companyList?.data?.listAccounts?.items)
          );
          tempNextToken = companyList?.data?.listAccounts?.nextToken;
          if (!tempNextToken) {
            await setTableLoading(false);
          }
        } while (tempNextToken);
      }
    }
  };
  const fetchAccountDetails = async () => {
    setLoading(true);
    const accountID = groups.length > 0 ? groups[0] : null;
    if (accountID) {
      let account = await sdk.fetchAccount(accountID);
      setCompanyData(account);
      setLoading(false);
    }
  };
  const setCompanyData = (account) => {
    setAcct(account);
  };
  const onInvite = () => {
    let data = { account_id: acct?.id, name: acct?.company };

    setinviteData(data);
  };
  const sendInvitation = async (value) => {
    value.account_id = recordData.id;
    value.name = recordData.company;
    setLoading(true);
    let result = await sdk.sendEmailInvitation(value);
    if (result.error) {
      message.error(result.error, 5);
      setLoading(false);
    } else {
      fetchAccountDetails();
      setLoading(false);
      inviteform.resetFields();
      message.success(`Invitation sent successfully.`, 5);
    }
    setIsInviteModalOpen(false);
    getCompanyData();
  };
  const sendMail = async (value) => {
    value.account_id = inviteData.account_id;
    value.name = inviteData.name;
    setLoading(true);
    let result = await sdk.sendEmailInvitation(value);
    if (result.error) {
      message.error(result.error, 5);
      setLoading(false);
    } else {
      fetchAccountDetails();
      setLoading(false);
      addcompanyform.resetFields();
      message.success(`Invitation sent successfully.`, 5);
    }
  };

  const onInviteSend = () => {
    setLoading(true);
    inviteform
      .validateFields()
      .then((values) => {
        const formatedValues = {
          ...values,
        };
        sendInvitation(formatedValues);
      })

      .catch((info) => {
        setLoading(false);
        console.log("Validate Failed:", info);
      });
  };
  const onInviteMail = () => {
    let count = 0;
    setLoading(true);
    addcompanyform
      .validateFields()
      .then((values) => {
        const formatedValues = {
          ...values,
        };
        // eslint-disable-next-line
        Companies.map((el) => {
          if (formatedValues.url === el.url) {
            count++;
          }
        });
        if (count === 0) {
          sendMail(formatedValues);
        } else console.log("Choose a Different Company Url");
      })
      .catch((info) => {
        setLoading(false);
        console.log("Validate Failed:", info);
      });
    count = 0;
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    let result = await sdk.createAccount(e.company, e.url);
    if (result.error) {
      message.error(result.error, 5);
    } else {
      message.success(`Company added successfully`, 5);
      addcompanyform.resetFields();
    }
    getCompanyData();
    setLoading(false);
    setAddCompanyDrawer(false);
  };

  useEffect(() => {
    onInvite();
    // eslint-disable-next-line
  }, [acct]);

  useEffect(() => {
    fetchAccountDetails();
    // eslint-disable-next-line
  }, []);

  const validateEmail = (e) => {
    const email_pattern = emailPattern;
    if (e.length > 0) {
      const found = e[e.length - 1].match(email_pattern);
      if (!found) {
        e.pop();
        message.error("Please enter valid email");
      } else {
        e[e.length - 1] = e[e.length - 1].toLowerCase();
      }
    }
  };
  return (
    <>
      <div>
        <Row>
          <Col span={8}></Col>
          <Col span={10} lg={10} md={10} sm={24} xs={24}>
            <Form
              autoComplete="off"
              name="search_Companies"
              onFinish={handle_search}
              initialValues={{ search_type: "Name" }}
              form={searchForm}
              layout="vertical"
              className="company-form"
            >
              <Form.Item
                name="search_type"
                label={<span style={{ fontSize: 18,fontWeight: 600 }}>Select Filter</span>}
                className="form-label"
                style={{ marginRight: "10px" }}
              >
                <Select
                  className="dropdown-text"
                  style={{ width: "125px", fontSize: 18, height: 44 }}
                  size="large"
                  onSelect={(e) => setSearchOption(e)}
                >
                  <Select.Option key="Name" style={{ fontSize: 16 }}>Name</Select.Option>
                  <Select.Option key="id" style={{ fontSize: 16 }}>Id</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="search_value"
                label={<span style={{ fontSize: 18,fontWeight: 600 }}>Search Company</span>}
                style={{ marginRight: "10px" }}
              >
                <Input
                  style={{ width: "270px", height: "44px", fontSize: 18 }}
                  placeholder={`Enter ${SearchOption}`}
                />
              </Form.Item>
              <Form.Item className="companysearchbtn">
                <Button
                className="save-btn font-family-style"
                  type="primary"
                  htmlType="submit"
                  style={{
                    height: "auto",
                    width: "auto",
                    background: "#416B88",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 18,
                    padding: "5px 15px",
                  }}
                 
                  disabled={TableLoading}
                >
                  Search
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col
            span={6}
            lg={6}
            md={6}
            sm={24}
            xs={24}
            className="company-add-account"
          >
            <Button
              onClick={() => setAddCompanyDrawer(true)}
              className="save-btn font-family-style"
              style={{
                height: "auto",
                background: "#416B88",
                color: "white",
                width: "auto",
                fontWeight: "bold",
                fontSize: 18,
                padding: "5px 18px",
              }}
            >
              Add Account
            </Button>
          </Col>
        </Row>
        <div>
        {TableLoading ? <div style={{background:'#fff',padding:20}}><Skeleton active paragraph={{rows:7}}/> </div>:
            <Table
              className="tablecell"
              bordered
              size="middle"
              dataSource={Companies}
              columns={columns}
            ></Table>
          }
        </div>
      </div>
      <Modal
        visible={isInviteModalOpen}
        closable={true}
        centered
        width={430}
        footer={[]}
        style={{ borderRadius: "20px" }}
        className="suspend-company-footer"
        onCancel={handleCancel}
      >
        <div className="delete-modal-body">
          <img
            src={InviteModalIcon}
            alt="suspend company"
            style={{ margin: "0 auto", display: "flex" }}
          />
          <Typography.Title
            style={{
              fontSize: "20px",
              fontWeight: 600,
              display: "flex",
              justifyContent: "center",
              paddingTop: "13px",
            }}
          >
            Invite Team Members
          </Typography.Title>
          <Typography.Text
            style={{
              textAlign: "center",
              fontWeight: 400,
              fontSize: 16,
              display: "flex",
            }}
          >
            Share direct invitation to your team members so they can join XaaS
            Pricing with your organization account ID
          </Typography.Text>
          <Form
            layout="vertical"
            className="input-modal"
            form={inviteform}
            requiredMark={false}
            style={{ paddingTop: "13px" }}
          >
            <Form.Item
              label={<span style={{ fontSize: 18 }}>Enter Email ID</span>}
              name="emails"
              rules={[
                {
                  required: true,
                  message: "This field can not be empty!",
                },
              ]}
            >
              <Select
                className="invitemail"
                mode="tags"
                style={{ width: "100%", borderRadius: 7, fontSize: 18 }}
                tokenSeparators={[","]}
                notFoundContent=""
                onChange={validateEmail}
                placeholder={
                  <span style={{ color: "grey" }}>Enter email id</span>
                }
              >
              </Select>
            </Form.Item>
          </Form>
          <Button
            style={{
              display: "flex",
              margin: "0 auto",
              width: "100%",
              height: "auto",
              background: "#416B88",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFFFFF",
              fontSize: "14px",
              fontWeight: "600",
              padding: "9px 12px",
            }}
            loading={loading}
            onClick={onInviteSend}
          >
            Share Invite
          </Button>
        </div>
      </Modal>

      <Drawer
        className="editcompany"
        width={430}
        title={
          <span style={{ color: "#303030", fontWeight: 600, fontSize: 18 }}>
            Add Company Details
          </span>
        }
        placement="right"
        onClose={() => {
          setAddCompanyDrawer(false);
        }}
        visible={addCompanyDrawer}
      >
        <Form
          labelCol={{ xs: 24 }}
          requiredMark={false}
          layout="vertical"
          name="new_account"
          className="login-data"
          onFinish={(e) => handleSubmit(e)}
          form={addcompanyform}
        >
          <Form.Item
            name="company"
            style={{
              marginTop: "0px",
              marginBottom: "12px",
            }}
            label={
              <span
                style={{ color: "#727272", fontWeight: 500, fontSize: "18px" }}
              >
                Company Name
              </span>
            }
            rules={[
              {
                required: true,
                message: "Please enter the name of your company",
              },
              {
                pattern: /(^[a-zA-Z0-9]{1})([a-zA-Z0-9& .]+)$/,
                message: "Please enter valid company name",
              },
            ]}
          >
            <Input
              size="middle"
              id="company"
              key="company"
              name="company"
              placeholder="Company Name"
              style={{ height: "44px" }}
            />
          </Form.Item>
          <Form.Item
            name="url"
            label={
              <span
                style={{ color: "#727272", fontWeight: 500, fontSize: "18px" }}
              >
                Company Website
              </span>
            }
            rules={[
              {
                required: true,
                message: "Please enter the URL of your company",
              },
              {
                pattern:
                  /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                message: "Please enter valid URL",
              },
            ]}
          >
            <Input
              size="middle"
              id="url"
              key="url"
              name="url"
              placeholder="Company Website"
              style={{ height: "44px" }}
            />
          </Form.Item>

          <Divider
            style={{ borderWidth: 6, borderColor: "#EFF4F9", width: "100%" }}
          />
          <Typography.Title style={{ fontSize: "18px", color: "black" }}>
            Invite Members
          </Typography.Title>
          <Form.Item
            name="emails"
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
            <Select
              className="invitemail"
              mode="tags"
              style={{ width: "100%", fontSize: "18px" }}
              tokenSeparators={[","]}
              notFoundContent=""
              onChange={validateEmail}
              placeholder={
                <span style={{ color: "grey" }}>Enter email id</span>
              }
            >
            </Select>
          </Form.Item>

          <div
            className="footer-buttons"
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#EFF4F9",
              textAlign: "left",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              className="secondary-btn"
              onClick={() => setAddCompanyDrawer(false)}
              style={{
                fontSize: 16,
                marginRight: 8,
                height: "40px",
                width: "45%",
              }}
            >
              Cancel
            </Button>
            <Button
            className="save-btn"
              onClick={onInviteMail}
              htmlType="submit"
              type="primary"
              style={{
                fontSize: 16,
                height: "40px",
                background: "#416B88",
                width: "48%",
                fontWeight: 600,
              }}
              loading={loading}
            >
              Add Account & Save
            </Button>
          </div>
        </Form>
      </Drawer>
      <Drawer
        className="editcompany"
        width={430}
        title={
          <span style={{ color: "#303030", fontWeight: 600 }}>
            <span icon={<LeftOutlined />}></span>Select users to invite
          </span>
        }
        placement="right"
        onClose={showInviteUserDrawerOnClose}
        visible={addUserInviteDrawer}
      >
        <Form
          layout="vertical"
        >
          <Form.Item>
            <Search
              className="usersearch"
              placeholder="Search Users"
              style={{ color: "black" }}
            />
          </Form.Item>

          <Radio.Group>
            <Space direction="vertical">
              <Radio value={1}>jameswilson45@gmail.com</Radio>
              <Radio value={2}>michaelanderson@gmail.com</Radio>
            </Space>
          </Radio.Group>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#EFF4F9",
              textAlign: "left",
            }}
          >
            <Button
              className="secondary-btn"
              onClick={() => setAddUserInviteDrawer(false)}
              style={{ fontSize: 16, marginRight: 8, height: "40px" }}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              style={{ fontSize: 16, height: "40px", background: "#416B88" }}
            >
              Add Account & Save
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default Companies;
