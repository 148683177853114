import React, { useState, useEffect } from "react";
import "./CompanyProfile.css";
import ProductIcon from "../../assets/productIcon.svg";
import { Table, Skeleton, Col, Empty } from "antd";
import { useParams } from "react-router-dom";
import sdk from "../../sdk/Accounts";

function PricingmodelProfile() {
  const [tableData, setTableData] = useState([]);
  const { id } = useParams();
  const [productName, setProductName] = useState("");
  const [TableLoading, setTableLoading] = useState(false);

  const getPricingModel = async () => {
    setTableLoading(true);
    await sdk.getCompanySpecific(id)
      .then((res) => {
        let tempKey = Object.keys(res?.products);
        let data = Object.keys(res?.products);
        let temp = [];
        tempKey.map((i) => {
          let tempData = res?.products[i]?.pricing_models 
          let productKey = Object.keys(tempData)
          let tempKey = []
          productKey.map((el) => {
         tempKey.push({...tempData[el][0],product : el})
           tempKey.sort((a, b) => a.product.localeCompare(b.product));
          return el;
          })
          temp.push(tempKey)
            return i;
          })
        setTableData(temp);
        setProductName(data);
        setTableLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setTableLoading(false);
      });
  };
  useEffect(() => {
    getPricingModel();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: "Product Edition Name",
      dataIndex: "product",
      key: "product",
      width: "20%",
      render: (record) => <span className="data-size">{record ? record : "-"}</span>,
    },
    {
      title: "Primary Value Metric",
      dataIndex: "primary value metric",
      key: "age",
      width: "20%",
      render: (record) => <span className="data-size">{record ? record : "-"}</span>,
    },
    {
      title: "Product Minimum (In Pricing Model)",
      dataIndex: "product minimum (in pricing model units)",
      key: "productminimum1",
      render: (record) => <span className="data-size">{record ? record : "-"}</span>,
    },
    {
      title: "Product Maximum (In Value Metric)",
      dataIndex: "product maximum (in value metric units)",
      key: "productminimum2",
      render: (record) => <span className="data-size">{record ? record : "-"}</span>,
    },
    {
      title: "Pricing Model Combinations",
      dataIndex: "pricing model combinations",
      key: "productminimum5",
      render: (record) => <span className="data-size">{record ? record : "-"}</span>,
    },
  ];

  return (
    <div className="main-product-overview-wrapper">
      {TableLoading ? (
        <div style={{ background: "#fff", padding: 20 }}>
          <Skeleton active paragraph={{ rows: 7 }} />{" "}
        </div>
      ) : tableData?.length > 0 ? (
        <>
         {productName?.map((i,index) => {
          return(
            <>
          <div className="col-icon">
            <div>
              <img src={ProductIcon} alt="" />
            </div>
            <div className="col-text">
              <span className="text-wrapper-title">{i}</span>
            </div>
          </div>
          <div className="table-wrapper">
          
              
                <Table
                 
                  className="table-css"
                  bordered
                  dataSource={tableData[index]}
                  columns={columns}
                  pagination={false}
                ></Table>
            
          </div>
        </>
           )
          })}
          </>
      ) : (
        <Col span={24}>
          <Empty className="empty-data" />
        </Col>
      )}
    </div>
  );
}

export default PricingmodelProfile;
