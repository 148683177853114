import React, { useEffect, useState } from "react";
import "./CompanyProfile.css";
import ProductIcon from "../../assets/productIcon.svg";
import { Table, Skeleton, Col, Empty } from "antd";
import { useParams } from "react-router-dom";
import sdk from "../../sdk/Accounts";

function ProductOverProfile() {
  const { id } = useParams();
  const [productName, setProductName] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [TableLoading, setTableLoading] = useState(false);
  
  const getProduct = async () => {
    setTableLoading(true);
    await sdk.getCompanySpecific(id)
      .then((res) => {
        let tempKey = Object.keys(res?.products);
        let data = Object.keys(res?.products);
        let temp = [];
        tempKey.map((i) => {
          let tempData = res?.products[i]?.products_overview
          let productKey = Object.keys(tempData)

          let tempKey = []
          productKey.map((el) => {
         tempKey.push({
              "product edition description":
              tempData[el]?.["product_edition_description"].split("-"),
                      edition: el,
            })
            tempKey.sort((a, b) => a.edition.localeCompare(b.edition));
          return el;
          })
          temp.push(tempKey)
            return i;
          })
        setTableData(temp);
        setProductName(data);
        setTableLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setTableLoading(false);
      });
  };

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: "Product Edition",
      dataIndex: "edition",
      key: "edition",
      width: "18%",
      render: (record) => {
        return <span className="data-size">{record}</span>;
      },
    },
    {
      title: "Product Edition Description",
      dataIndex: "product edition description",
      key: "description",
      render: (record, text) => {
        return record.map((item) => {
          if (item !== "" && item !== undefined && item.length > 1) {
            return (
              <>
                <span className="data-size">- {item}</span>
                <br />
              </>
            );
          }
          return item;
        });
      },
    },
  ];

  return (
    <div className="main-product-overview-wrapper">
      {TableLoading ? (
        <div style={{ background: "#fff", padding: 20 }}>
          <Skeleton active paragraph={{ rows: 7 }} />{" "}
        </div>
      ) : tableData?.length > 0 ? (
        <>
        {productName?.map((i,index) => {
          return(
            <>
        <div className="col-icon">
          <div>
            <img src={ProductIcon} alt="" />
          </div>
          <div className="col-text">
                <span className="text-wrapper-title">{i}</span>
          </div>
        </div>
        <div className="table-wrapper">
          <Table
            className="table-css"
            bordered
            dataSource={tableData[index]}
            columns={columns}
            pagination={false}
          ></Table>
        </div>
            </>
          )
        })}
        
        </>
     ) : (
        <Col span={24}>
          <Empty className="empty-data" />
        </Col>
      )} 
    </div>
  );
}

export default ProductOverProfile;
