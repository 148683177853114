import React, { useEffect, useState } from "react";
import SupportIcon from "../../assets/supportGroup.svg";
import phoneicon from "../../assets/phoneicon.svg";
import emailIcon from "../../assets/Email.svg";
import timeIcon from "../../assets/Time.svg";
import "./support.css";
import { Row, Col, Form, Input, Divider, Button, message } from "antd";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";

function Support() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { userData } = useSelector((state) => state.loginReducer);
  const [loading, setloading] = useState(false);

  const setUserData = () => {
    form.resetFields();
    form.setFieldsValue(userData);
  };

  //submit detial function
  const submitDetails = async () => {
    setloading(true);
    form
      .validateFields()
      .then((values) => {
        if (values.email)
          API.post("XaaSPricingREST", "/support", {
            body: values,
          })
            .then(() => {
              message.success("Thank you for your words");
              setloading(false);
              setUserData();
            })
            .catch(() => {
              message.error("Something went wrong");
              setloading(false);
              setUserData();
            });
        else message.error("Something went wrong");
      })
      .catch((error) => console.log("Field Validation error", error));
  };

  useEffect(() => {
    setUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <div
      style={{
        width: "100%",
        background: "white",
        borderRadius: "8px",
      }}
    >
      <div className="icon-support">
        <img src={SupportIcon} alt="" className="img" />
      </div>
      <Row justify="center">
        <Col span={18}>
          <h2 className="main-title">Submit a Question or Request</h2>
          <Form
            layout="vertical"
            onFinish={submitDetails}
            form={form}
            requiredMark={false}
          >
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span
                      style={{
                        fontSize: 18,
                        color: "#727272",
                        fontWeight: 600,
                      }}
                    >
                      First Name
                    </span>
                  }
                  name="given_name"
                >
                  <Input type="text" className="input" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span
                      style={{
                        fontSize: 18,
                        color: "#727272",
                        fontWeight: 600,
                      }}
                    >
                      Last Name
                    </span>
                  }
                  name="family_name"
                >
                  <Input type="text" className="input" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span
                      style={{
                        fontSize: 18,
                        color: "#727272",
                        fontWeight: 600,
                      }}
                    >
                      Phone Number
                    </span>
                  }
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your phone number",
                    },
                    {
                      pattern: /^([0|\\+[0-9]{1,3})?([0-9]{10})$/,
                      message: "Please Enter Valid Mobile Number",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    className="input"
                    disabled={loading}
                    maxLength="10"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span
                      style={{
                        fontSize: 18,
                        color: "#727272",
                        fontWeight: 600,
                      }}
                    >
                      Email ID
                    </span>
                  }
                  name="email"
                >
                  <Input type="text" className="input" disabled />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={
                    <span
                      style={{
                        fontSize: 18,
                        color: "#727272",
                        fontWeight: 600,
                      }}
                    >
                      Message/Query
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter message for us!",
                    },
                  ]}
                  name="message"
                >
                  <TextArea
                    rows={4}
                    disabled={loading}
                    autoSize={{ minRows: 5, maxRows: 25 }}
                    style={{ fontSize: 18 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Button className="btn-submit" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form>

          <Divider />

          <Row gutter={20} style={{ paddingBottom: 30 }}>
            <Col span={24}>
              <h2 className="child-title">Connect With Us Directly</h2>
            </Col>
            <Col span={7} md={7} lg={7} xs={24}>
              <div className="icon-text">
                <div>
                  <img src={phoneicon} alt="" />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className="h2-text">Phone</span>
                  <span className="number">(603) 758-1859</span>
                </div>
              </div>
            </Col>
            <Col span={10} md={10} lg={10} xs={24}>
              <div className="icon-text">
                <div>
                  <img src={emailIcon} alt="" />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className="h2-text">Email</span>
                  <span className="number">
                    <a
                      href="mailto:support@xaaspricing.com"
                      target="_blank"
                      rel="noreferrer"
                      className="a"
                    >
                      support@xaaspricing.com
                    </a>
                  </span>
                </div>
              </div>
            </Col>
            <Col span={7} md={7} lg={7} xs={24}>
              <div className="icon-text">
                <div>
                  <img src={timeIcon} alt="" />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className="h2-text">Available Hours</span>
                  <span className="number">
                    8am - 5pm, Eastern Time, Mon to Fri.
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Support;
