import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
  Skeleton,
  Descriptions,
  Tooltip,
  Typography,
} from "antd";
import { API, Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { setUserName } from "../../store/action/loginAction";
import { useSelector } from "react-redux";
import sdk from "../../sdk/Accounts";
import { EditOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

function UserSettings() {
  const [profileForm] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();
  const [acct, setAcct] = useState(null);
  const [company, setCompany] = useState([]);
  const { groups, accountDetails } = useSelector((state) => state.loginReducer);
  const history = useHistory();

  // Function to fetch current user information
  const getCurrentUserInfo = async () => {
    const currentUserVal = await Auth.currentUserInfo();
    setEmail(currentUserVal?.attributes?.email);
    profileForm.setFieldsValue(currentUserVal?.attributes);
  };

  const fetchAccountDetails = async () => {
    const accountID = groups.length > 0 ? groups[0] : null;
    if (accountID) {
      let account = await sdk.fetchAccount(accountID);
      setCompanyData(account);
    }
  };

  const setCompanyData = (account) => {
    setAcct(account);
  };

  useEffect(() => {
    fetchAccountDetails();
    // eslint-disable-next-line
  }, []);

  // Function to handle update profile
  const handleUpdateProfile = (value) => {
    setLoading(true);
    let formvalue = profileForm.getFieldValue();
    let data = {
      email: formvalue.email,
      family_name: formvalue.family_name,
      given_name: formvalue.given_name,
    };
    let init = {
      body: {
        ...data,
      },
    };
    API.post("XaaSPricingREST", `/user/update`, init)
      .then((data) => {
        dispatch(setUserName(formvalue.given_name));
        message.success("Profile Updated");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const setCompnaysData = () => {
    let data = [];
    if (accountDetails?.segment_comapany !== null) {
      JSON.parse(accountDetails?.segment_comapany).map((item) => {
        data.push(item?.company_name);
        return data;
      });
    }
    setCompany(data);
  };

  useEffect(() => {
    getCurrentUserInfo();
    setCompnaysData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="sub-container">
        {!email?.length > 0 ? (
          <Skeleton active />
        ) : (
          <Form
            name="basic"
            layout="vertical"
            autoComplete="off"
            form={profileForm}
            onFinish={handleUpdateProfile}
          >
            <Row>
              <Col span={8} lg={8} md={8} sm={24} xs={24}>
                <Form.Item
                  name="given_name"
                  className="font-weight"
                  label={<span style={{ fontSize: 18 }}>First Name</span>}
                  style={{ marginRight: "10px" }}
                >
                  <Input
                    style={{ height: "44px", fontWeight: 400, fontSize: 18 }}
                  />
                </Form.Item>
              </Col>
              <Col span={8} lg={8} md={8} sm={24} xs={24}>
                <Form.Item
                  className="font-weight"
                  name="family_name"
                  label={<span style={{ fontSize: 18 }}>Last Name</span>}
                  style={{ marginRight: "10px" }}
                >
                  <Input
                    style={{ height: "44px", fontWeight: 400, fontSize: 18 }}
                  />
                </Form.Item>
              </Col>
              <Col span={8} lg={8} md={8} sm={24} xs={24}>
                <Form.Item
                  className="font-weight"
                  name="email"
                  label={<span style={{ fontSize: 18 }}>Email</span>}
                  style={{ marginRight: "10px" }}
                >
                  <Input disabled style={{ height: "44px", fontSize: 18 }} />
                </Form.Item>
              </Col>
              <Col span={8} lg={8} md={8} sm={24} xs={24}>
                <Button
                  className="save-btn"
                  style={{
                    fontWeight: "600px",
                    width: "auto",
                    height: "auto",
                    background: "#416B88",
                    fontSize: "18px",
                    color: "white",
                    padding: "6px 25px",
                    fontFamily: "Matter-bold",
                  }}
                  loading={loading}
                  type="primary"
                  size="small"
                  htmlType="submit"
                  block
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </div>
      {groups.includes("individual") && (
        <div className="sub-container" style={{ marginTop: 10 }}>
          <Row>
            <Col className="company-detail">
              <span
                style={{
                  fontWeight: 600,
                  fontSize: 18,
                  fontFamily: "Matter-bold",
                }}
              >
                Company Information
              </span>
              <Descriptions bordered column={1} style={{ paddingTop: 14 }}>
                <Descriptions.Item
                  labelStyle={{ fontWeight: "400", fontSize: "18px" }}
                  label="Company Name"
                >
                  <span style={{ fontSize: "18px", color: "#727272" }}>
                    {accountDetails?.company !== null &&
                    accountDetails?.company !== ""
                      ? accountDetails?.company
                      : "-"}
                  </span>
                </Descriptions.Item>
                <Descriptions.Item
                  labelStyle={{ fontWeight: "400", fontSize: "18px" }}
                  label="Company URL"
                >
                  <a
                    style={{ fontSize: "18px", color: "#727272" }}
                    href={accountDetails?.url}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {accountDetails?.url === "" ||
                    accountDetails?.url === "https://"
                      ? "-"
                      : accountDetails?.url}
                  </a>
                  {accountDetails?.url === "" ||
                  accountDetails?.url === "https://" ? (
                    <Tooltip
                      placement="topLeft"
                      title="Please edit here"
                      style={{ fontWeight: "bold" }}
                    >
                      <EditOutlined
                        style={{
                          cursor: "pointer",
                          fontSize: 15,
                          color: "#727272",
                          marginLeft: 16,
                        }}
                        onClick={() => history.push("/details")}
                      />
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </Descriptions.Item>

                <Descriptions.Item
                  className="label-detail"
                  labelStyle={{ fontWeight: "400", fontSize: "18px" }}
                  label="Companies"
                >
                  <span style={{ fontSize: "18px", color: "#727272" }}>
                    {company !== null && company !== undefined
                      ? company
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "-"}
                  </span>
                  <Tooltip
                    placement="topLeft"
                    title="Please edit here"
                    style={{ fontWeight: "bold" }}
                  >
                    <EditOutlined
                      style={{
                        cursor: "pointer",
                        fontSize: 15,
                        color: "#727272",
                        marginLeft: 16,
                      }}
                      onClick={() => history.push("/details")}
                    />
                  </Tooltip>
                </Descriptions.Item>
                <Descriptions.Item
                  labelStyle={{ fontWeight: "400", fontSize: "18px" }}
                  label="Account ID"
                >
                  <span style={{ fontSize: "18px", color: "#727272" }}>
                    {acct ? (
                      <Typography.Text copyable={{ text: acct.id }}>
                        {acct.id}
                      </Typography.Text>
                    ) : (
                      "-"
                    )}
                  </span>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default UserSettings;
