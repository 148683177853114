import React, { useEffect, useState } from "react";
import "./CompanyProfile.css";
import ProductIcon from "../../assets/productIcon.svg";
import { Table, Skeleton, Col, Empty } from "antd";
import { useParams } from "react-router-dom";
import sdk from "../../sdk/Accounts";

function PricingDiscountingModelProfile() {
  const [tableData, setTableData] = useState([]);
  const { id } = useParams();
  const [productName, setProductName] = useState("");
  const [TableLoading, setTableLoading] = useState(false);

  const getPricingDiscountingModel = async () => {
    setTableLoading(true);
    await sdk
      .getCompanySpecific(id)
      .then((res) => {
        let tempKey = Object.keys(res?.products);
        let data = Object.keys(res?.products);
        let temp = [];
        tempKey.map((i) => {
          let tempData = res?.products[i]?.pricing_discounting;
          let productKey = Object.keys(tempData);
          let tempKey = [];
          productKey.map((el) => {
            tempKey.push({ ...tempData[el][0], product: el });
            tempKey.sort((a, b) => a.product.localeCompare(b.product));
            return el;
          });
          temp.push(tempKey);
          return i;
        });
        setTableData(temp);
        setProductName(data);
        setTableLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setTableLoading(false);
      });
  };

  useEffect(() => {
    getPricingDiscountingModel();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: "Product Edition Name",
      dataIndex: "product",
      key: "product",
      width: "15%",
      render: (record) => <span className="data-size">{record ? record : "-"}</span>,
    },
    {
      title: "Published List Price (In $USD) - Pricing Model 1",
      dataIndex: "published list price (in $usd)",
      key: "list",
      render: (record) => <span className="data-size">{record ? record : "-"}</span>,
    },
    {
      title: "Published Annual List Price (In $USD) - Pricing Model 1",
      dataIndex: "published annual list price (in $usd)",
      key: "annual",
      render: (record) => <span className="data-size">{record ? record : "-"}</span>,
    },
    {
      title: "% Discount For Annual Commitment",
      dataIndex: "% discount for annual commitment",
      key: "discount",
      render: (record) => <span className="data-size">{record ? record : "-"}</span>,
    },
    {
      title: "Pricing Multiple Over Previous Edition",
      dataIndex: "pricing multiple over previous edition",
      key: "multiple",
      render: (record) => <span className="data-size">{record ? record : "-"}</span>,
    },
    {
      title: "Pricing Multiple Over Previous Edition - Annual",
      dataIndex: "pricing multiple over previous edition - annual",
      key: "pricing",
      render: (record) => <span className="data-size">{record ? record : "-"}</span>,
    },
  ];

  return (
    <div className="main-product-overview-wrapper">
      {TableLoading ? (
        <div style={{ background: "#fff", padding: 20 }}>
          <Skeleton active paragraph={{ rows: 7 }} />{" "}
        </div>
      ) : tableData?.length > 0 ? (
        <>
          {productName?.map((i, index) => {
            return (
              <>
                <div className="col-icon">
                  <div>
                    <img src={ProductIcon} alt="" />
                  </div>
                  <div className="col-text">
                    <span className="text-wrapper-title">{i}</span>
                  </div>
                </div>
                <div className="table-wrapper">
                  <Table
                    className="table-css"
                    bordered
                    dataSource={tableData[index]}
                    columns={columns}
                    pagination={false}
                  ></Table>
                </div>
              </>
            );
          })}
        </>
      ) : (
        <Col span={24}>
          <Empty className="empty-data" />
        </Col>
      )}
    </div>
  );
}

export default PricingDiscountingModelProfile;
