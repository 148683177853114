import React, { useEffect, useState } from "react";
import "./CompanyProfile.css";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { Row, Col, List, Typography, Skeleton, message, Input } from "antd";
import { RightOutlined, SearchOutlined } from "@ant-design/icons";
import Website from "../../assets/region.svg";
import HeadQuarter from "../../assets/url.svg";
import { useHistory } from "react-router-dom";

function CompanyPreview() {
  const { Search } = Input;

  const history = useHistory();
  const [storeData, setStoreData] = useState([]);
  const [previewLoader, setPreviewLoader] = useState(false);
  const [allCompanyData, setAllCompanyData] = useState([]);
  const { accountDetails } = useSelector((state) => state.loginReducer);

  const onSearch = (e) => {
    if (e !== "") {
      let temp = allCompanyData.filter((item) =>
        item.company.toLowerCase().includes(e.toLowerCase())
      );
      setStoreData(temp);
    } else {
      setStoreData(allCompanyData);
    }
  };

  const getProduct = async () => {
    setPreviewLoader(true);
    let temp = [];
    JSON.parse(accountDetails?.segment_comapany).map((item) => {
      temp.push(item?.id);
      return temp;
    });
    let companyID = temp?.toString();
    let path = `/redshiftDataManager/getCompanyDetails?companyid=${companyID}`;
    let tempData = [];
    await API.get("XaaSPricingREST", path)
      .then((res) => {
        res.map((item) => {
          tempData.push({
            id: item[0],
            company: item[1],
            website: item[2],
            region: item[3],
          });
          return res;
        });
        setStoreData(tempData);
        setAllCompanyData(tempData);
        setPreviewLoader(false);
      })
      .catch((err) => {
        message.err("Failed to fetch companies");
        setPreviewLoader(false);
      });
  };

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, []);
  
  return (
    <>
      <div className="preview-page">
        <Row justify="center" gutter={[20, 0]}>
          <Col span={23} md={23} lg={23} xs={24}>
            <div
              className="company-text"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <span className="title-clss">All Company Profiles</span>
              <Search
                className="search-view-container "
                prefix={<SearchOutlined />}
                placeholder="Search Companies"
                size="large"
                onChange={(e) => onSearch(e.target.value)}
                style={{
                  width: 200,
                  fontSize: 16,
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="preview-page-child">
        <Row gutter={[20, 0]} className="preview-page">
          <Col span={24} md={24} lg={24} xs={24} style={{ marginBottom: 20 }}>
            <div className="company-text-clss">
              {previewLoader ? (
                <div
                  style={{
                    background: "#fff",
                    padding: 20,
                  }}
                >
                  <Skeleton active paragraph={{ rows: 7 }} />{" "}
                </div>
              ) : (
                <List
                  className="list-css"
                  itemLayout="horizontal"
                  pagination={{
                    onChange: (page) => {
                      console.log(page);
                    },
                    pageSize: 12,
                  }}
                  dataSource={storeData}
                  renderItem={(item) => (
                    <List.Item
                      onClick={() =>
                        history.push(`/company-profile/${item.id}`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <List.Item.Meta
                        title={
                          <div className="list-text">
                            <Typography.Text className="typogrphy-text-css">
                              {item.company}
                            </Typography.Text>
                            <RightOutlined
                              className="right-icon"
                              onClick={() =>
                                history.push(`/company-profile/${item.id}`)
                              }
                            />
                          </div>
                        }
                        description={
                          <>
                            <Row>
                              <Col span={4} md={4} lg={4} xs={24}>
                                <img
                                  className="desc-data"
                                  src={Website}
                                  alt=""
                                />
                              </Col>
                              <Col span={20} md={20} lg={20} xs={24}>
                                <span className="link-wrapper">
                                  {item.website}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={4} md={4} lg={4} xs={24}>
                                <img
                                  className="desc-data"
                                  src={HeadQuarter}
                                  alt=""
                                />
                              </Col>
                              <Col span={20} md={20} lg={20} xs={24}>
                                <span className="link-wrapper">
                                  {item.region}
                                </span>
                              </Col>
                            </Row>
                          </>
                        }
                      />
                    </List.Item>
                  )}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CompanyPreview;
